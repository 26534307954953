import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { UserDetails } from '../../interfaces/userDetails.interface';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LocalStorageKey } from '../../constants/local-storage-key.const';
import { Params, Router } from '@angular/router';
import { PermissionSet } from '../../interfaces/userPermissions.interface';

@Injectable({
  providedIn: 'root',
})
export class AdiUserDetailsService {
  private router = inject(Router);

  currentUserDetails$ = new BehaviorSubject<UserDetails | undefined>(undefined);
  searchText: string = '';
  searchType: string | null = null;

  constructor(private http: HttpClient) {}
  getCurrentUserEntitlements(
    order?: string,
    page?: number,
    pageSize?: number,
    label?: string,
    searchText?: string,
    callFacilitiesApi = false
  ): Observable<UserDetails> {
    let apiEndPoint = `${environment.API_BE_ENDPOINT}/auth/users/current/entitlements`;

    if (callFacilitiesApi) {
      apiEndPoint = `${environment.API_BE_ENDPOINT}/auth/facility/facilities`;
    }

    const params: Params = {};
    if (order !== undefined) params.sort = order;
    if (page !== undefined) params.page = page;
    if (pageSize !== undefined) params.pageSize = pageSize;
    if (label !== undefined && searchText !== '') params[label] = searchText;
    const queryString = new URLSearchParams(params as any).toString();
    const url = queryString ? `${apiEndPoint}?${queryString}` : apiEndPoint;

    return this.http.get<UserDetails>(url);
  }

  getAccountSpecificEntitlements(
    userID: string,
    accountID: string,
    practiceOrgId: string | undefined
  ): Observable<UserDetails> {
    const apiEndPoint = `${environment.API_BE_ENDPOINT}/auth/users/${userID}/account/${accountID}/entitlement?practiceOrgId=${practiceOrgId}`;
    return this.http.get<UserDetails>(apiEndPoint);
  }

  getSpecificEntitlements(accountID: string): Observable<UserDetails> {
    const apiEndPoint = `${environment.API_BE_ENDPOINT}/auth/users/current/entitlements`;
    const params: Params = {};
    params.sapAccountId = accountID;
    const queryString = new URLSearchParams(params as any).toString();
    const url = queryString ? `${apiEndPoint}?${queryString}` : apiEndPoint;
    return this.http.get<UserDetails>(url);
  }

  getAddressDetails(id: string): Observable<any> {
    const url = `${environment.API_BE_ENDPOINT}/auth/accounts/${id}/shiptos`;
    return this.http.get<any>(url);
  }

  setCurrentUserDetails(data: UserDetails | undefined) {
    this.currentUserDetails$.next(data);
  }

  getCurrentUserDetails() {
    return this.currentUserDetails$.asObservable();
  }

  callUserDetails(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getCurrentUserEntitlements().subscribe({
        next: (res: UserDetails) => {
          this.setCurrentUserDetails(res);
          resolve(true);
        },
        error: (err) => {
          if (err?.status === 404) {
            localStorage.setItem('adiErrorPopup', 'true');
            this.router.navigate([
              `${LocalStorageKey.SELECTED_LANGUAGE}/logout`,
            ]);
          }
          reject();
        },
      });
    });
  }

  updateEntitlement(
    userID: any,
    accountID: string,
    entitlementPayload: {
      permissions: PermissionSet;
      accessType: string;
    }
  ) {
    const apiEndPoint = `${environment.API_BE_ENDPOINT}/auth/users/${userID}/account/${accountID}/entitlement`;
    return this.http.put<UserDetails>(apiEndPoint, entitlementPayload);
  }

  setSearch(searchType: string, searchText: string) {
    if (searchType) {
      this.searchText = searchText;
      this.searchType = searchType;
    } else {
      this.resetSearchData();
    }
  }
  getSearchData() {
    return {
      searchText: this.searchText,
      searchType: this.searchType,
    };
  }
  resetSearchData() {
    this.searchText = '';
    this.searchType = null;
  }
}
